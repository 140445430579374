<template>
    <layout-full-width title="Internetmarke">

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
export default {
    name: "PageInternetmarke",
    components: { LayoutFullWidth }
}
</script>

<style scoped>

</style>